@import '../../../common/styles/utils/_colors.util'

footer.default-footer
  padding: 2rem 0
  border-top: 1px solid $alt_dark-3
  text-align: center
  p.author
    span.icon
      margin: 0 0.3rem
      &:first-of-type
        color: #b83d3d
      &:nth-last-of-type(2)
        color: #c5a63e
    code
      word-spacing: -0.3rem
  p.reservation
    span.icon
      font-size: 22px
      display: inline-block
      margin-right: 0.1rem
    span
      margin: 0 0.4rem
      a
        color: gray !important
