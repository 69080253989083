.tditor-ant-list-item {
    border-bottom: 1px solid rgba(0, 0, 0, .1);
}

.ant-list-item-meta {
    display: flex;
    flex: 1 1;
    align-items: flex-start;
    max-width: 100%;
    margin-bottom: 16px;
}

.ant-list-item-meta-content {
    flex: 1 0;
    width: 0;
    color: rgba(0, 0, 0, 0.85);
}

.ant-list-item-meta-title {
    margin-bottom: 4px;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    line-height: 3;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
    width: 200px;
}

.ant-list-item-meta-description {
    color: rgba(0, 0, 0, 0.45);
    font-size: 14px;
    line-height: 0;
}

.ant-list-item {
    border-bottom: 1px solid rgba(0, 0, 0, .1) !important;
}
