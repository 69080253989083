// colors and trans:

  /* CHANGE AND MOVE THINGS AROUND IN THIS FILE WHILE OYU ARE WORKING...  */

// app colors AKA, APP IDENTITY::
$main_clr: #345f83
$main_clr-1: #5785aa
$main_clr-2: #83b2d8
$additional_clr: #91784a
$additional_clr-1: #af925b
$additional_clr-2: #d3b885

// COLORS GRADS::
// basic:
$main_white: #fff
$main_dark: #111

// rest of darks `grays`:
//$app_gray: #4c555a
$app_gray: #020202
$dark_gray: #373d41
$alt_dark: #1e1f20
$alt_dark-2: #303235
$alt_dark-3: #ddd
$alt_dark-4: #ccc

// rest of whites:
$app_white: #e5e5fa
$dark_white: #f7f7f7
$off_white: #fbfbfd

// trans:
$main_trans: #00000094
$alt_trans: #0000004d
$white_trans: #ffffff7a
$grad_white_trans: linear-gradient(45deg, #ffffffde, #ffffff5e)
$grad_dark_trans: linear-gradient(45deg, #000000d8, #0000004b)
