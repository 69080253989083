
.input-wrapper
  width: 100%
  margin: 1rem 0
  margin-bottom: 6px
  label
    margin-bottom: 0.1rem
    // color: $gray
    font-size: 15px
  input
    transition: 0.5s
    outline: none
    padding: 0.7rem 0.5rem
    display: block
    width: inherit
    // border: 1px solid $light_gray
    border: none
    border-bottom: 1px solid #005aa7
    //border-radius: 6px
    // color: $gray
    font: 25px Arial

input:focus
  border-bottom: 2px solid #005aa7
