// setup custome font:
@font-face
  font-family: Operator Mono Lig
  src: url("../../../public/assets/fonts/OperatorMonoLig-Book.otf") url("../../../public/assets/fonts/OperatorMonoLig-BookItalic.otf") url('../../../public/assets/fonts/OperatorMonoLig-MediumItalic.otf') url('../../../public/assets/fonts/OperatorMonoLig-Medium.otf') format("truetype")

// gloabls:
main.page
  min-height: 90vh
  padding-top: 0.5rem
  justify-content: center
  display: flex
