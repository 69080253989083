@import '../../common/styles/utils/_mixins.util'
@import '../../common/styles/utils/_colors.util'

main.page.notfound-page
  background: linear-gradient(45deg, #fdfdfdd0, #ffffff93), url('../../../public/assets/images/notfound-cover.svg') center/cover
  text-align: center
  .page-wrapper
    height: 50vh
    @include flex_positioning(center, center)
  .notfound-number
    font-size: 90px
    color: $main_dark
    font-weight: 900
  .notfound-description
    margin: 2rem
  .redirect-home
    background: $app_white
    border: 2px solid $main_clr
    padding: 0.5rem 1rem
    height: 2rem
    min-width: 5rem
    border-radius: 6px
    color: #444
    text-transform: uppercase
