@import '../../../common/styles/utils/_mixins.util'
@import '../../../common/styles/utils/_colors.util'

header.default-header
  border-bottom: 1px solid $alt_dark-3
  .header-wrapper
    @include flex_positioning(center, center)
    height: 60px
    section
      height: inherit
      @include flex_positioning(center, center)
      padding: 0 1rem
      &.left-name
        margin-right: 0rem
        flex-grow: 0
        justify-content: flex-start
        border-bottom: 0px

      &.left-wing
        margin-right: 0.1rem
        flex-grow: 0
        justify-content: flex-start
        .logo
          display: inline-block
          height: 40px
          width: 150px
      .head-text
        color: black
        font-size: 20px
        padding-left: 0px
      .head-sub-title
        color: gray
        font-size: 15px
      a
        display: block
        height: inherit
        width: inherit
        border-radius: 4px
        background: url('../../../../public/assets/images/logo.png') center/cover

      &.right-wing
        justify-content: flex-end
        margin-left: 0.1rem
        flex-grow: 4
